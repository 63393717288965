import React, { Suspense, useState } from "react";
import { Col, Row, Container } from "reactstrap";
import { HiHeart, HiOutlineUsers } from "react-icons/hi";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Typewriter from "typewriter-effect";
import image from "../images/genre/aldreboende.jpeg";
const ParticleBackground = React.lazy(() => import("../ParticleComponent"));
function Omsorg(){

    const HEADER = {   
        header1: "Behöver du stöd ska du få det",
        header2: "Gör det tryggt att åldras"    
        }
    
    const LISTTEXT = {
        list1: 
            [{id: 1, text: "Inrätta fler familjecentraler och öka samordningen med regionen."},
            {id: 2, text: "Att det på varje skola ska finnas ett aktivt elevhälsoteam som särskilt ska rikta in sig på psykisk ohälsa."},
            {id: 3, text: "Att elevhälsan ska genomföra kontinuerliga hälsosamtal för att upptäcka dåligt mående bland unga."}
            ],
        list2:[{id: 1, text: "Öka grundbemanningen i äldreomsorgen. Fler under- och sjuksköterskor, men också servicepersonal ska anställas."},
            {id: 2, text: "Göra riktade lönesatsningar till undersköterskor."},
            {id: 3, text: "Ha fler karriärvägar inom omsorgsyrkena."},
            {id: 4, text: "Säkerställa att medarbetarna kan tillräckligt bra svenska, genom utbildning och språktester."},
            {id: 5, text: "Ha kommunala träffpunkter för äldre med hög kvalitet och ökad tillgänglighet."},
            {id: 6, text: "Avskaffa LOVen inom hemtjänsten."},
            {id: 7, text: "Utveckla samverkan mellan kommunen och andra myndigheter för att stödja människor i svåra situationer."},
        ]
        }

    const [done, setDone] = useState(false);

    const BodyText1 = () => {return(
        <div>
            <p>Hälsan är ojämlik i Norrköping. Det spelar roll om du bor i Lindö eller i Hageby. Så ska det inte vara. Skillnader i hälsa kan till exempel bero på om du har ett arbete att gå till, hur ditt boende ser ut, vilken utbildningsnivå du har, om du röker och vad du äter. För oss socialdemokrater måste alltid människors livsvillkor vara i fokus och att dessa ska utjämnas för att nå en jämlik hälsa i befolkningen. </p>
            <p>För att alla ska få lika möjlighet till en god hälsa behöver en rad förebyggande åtgärder genomföras. Det behövs både generella insatser som gäller alla blandat med punktinsatser där skillnader i hälsa är som störst. Vi vill införa familjecentraler i strategiskt valda stadsdelar i Norrköping. Familjecentraler är ett bra sätt att fånga upp familjer tidigt och där närhet till både kommunens och regionens verksamheter finns. Det är också en bra arena för föräldrar att byta erfarenheter med varandra. </p>
            <p>Den psykiska ohälsan ökar i alla åldersgrupper. Vårt svar är tydligt, behöver du stöd ska du få det. För att lyckas med detta behöver det finnas en god samverkan mellan bland annat skolan och vården. På varje skola ska det finnas ett aktivt elevhälsoteam som särskilt ska rikta in sig på psykisk ohälsa. </p>
        </div>)};
    
    const BodyText2 = () => {return(
        <div>
            <p>I Norrköping ska det vara tryggt att åldras. Äldreomsorgen ska präglas av hög kvalitet och god kontinuitet. Som vårdbehövande äldre och anhörig ska du känna igen personalen som tar hand om dig. För att lyckas med detta behöver vi höja grundbemanningen genom att anställa fler under- och sjuksköterskor. Villkoren för personalen inom äldreomsorgen måste också bli bättre.</p>
            <p>Undersköterskor ska få ägna sin tid till det man är bäst på, nämligen omvårdnad. Därför vill vi anställa servicepersonal, som kan avlasta omsorgspersonalen vid exempelvis mathantering och städning. Då undersköterskornas löner ligger efter i jämförelse med andra grupper så vill vi under mandatperioden göra en riktad lönesatsning på den gruppen.
            Språk är en nödvändig nyckel för kommunikation. Därför vill vi matcha medarbetare med språkkompetens med omsorgstagarnas behov. Vi vill fortsätta att utveckla arbetet med språktester i svenska för personal i kommunen. Arbetsgivarna ska erbjuda befintlig personal med otillräckliga språkkunskaper utbildning och språkutveckling i svenska språket parallellt med anställningen. </p>
            <p>På våra särskilda boenden ska det finnas meningsfulla aktiviteter. Ingen människa ska behöva hamna mellan stolarna. Insatser för att bryta social isolering och den ofrivilliga ensamheten är viktiga delar för att öka den psykiska hälsan bland våra äldre.</p>
            <p>Kommunens träffpunkter är viktiga. Att möta andra i samma livssituation ger mening i vardagen och bidrar till många sociala kontakter. Genom samverkan med föreningslivet, t.ex. PRO, ska det bli ännu fler mötesplatser för äldre. </p>
            <p>Vi ser positivt på privata utförare, men vill värna kvaliteten för omsorgstagarna och hitta en modell som är långsiktigt hållbar. Därför vill vi avskaffa lagen om valfrihetssystem (LOV) inom hemtjänsten i Norrköpings kommun.</p>
        </div>)};


 
    const HeaderText = (headerText) => {
        return(
        <Typewriter 
            options={{
                cursor: "",
                delay:50
            }}
            onInit={(typewriter)=>{
                typewriter.typeString(headerText).pauseFor(1000).callFunction(NewHeader, true).start()
            }}
            />
            )
    }

    function NewHeader(){
        setDone(true)
    }

    function SmallHeaderText(){
        if (done === true){
        return(
        <Typewriter 
            options={{
                cursor: "",
                delay:50
            }}
            onInit={(typewriter)=>{
                typewriter.typeString("Socialdemokraterna Norrköping vill: ").start()
            }}
            />)}
    }
    const ListOfItems = (list, icon) =>{
        return( 
        <TransitionGroup>
        { list.map((elements) => ( 
            <CSSTransition
                timeout={3000}  
                classNames="fade"
                appear={true}
                in={true}
                key={elements.id}
                >                      
            <li>
                {icon} {elements.text}
            </li>
            </CSSTransition>
        )) }
        </TransitionGroup>)}

    const ListOfBodyText = (bodyText) => {
        return(
            <TransitionGroup>
            <CSSTransition
                timeout={500}  
                classNames="fade"
                appear={true}
                in={true}
                >                     
                {bodyText} 
            </CSSTransition>
        </TransitionGroup>)}

    
    return(
        <div className="App-main">
        <Suspense fallback={<div className="pink" />}>  
             <ParticleBackground />
        </Suspense>
        <Container>
            <Row>
                <h1>{HeaderText(HEADER.header1)}</h1>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                    {ListOfBodyText(BodyText1)}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <ul>
                        <li>
                            <h2>
                               {SmallHeaderText()}
                            </h2>
                        </li>
                        {ListOfItems(LISTTEXT.list1, <HiOutlineUsers color="#ED1B34" />)}
                    </ul>
                </Col>
            </Row>
            <Row className="image-container">
            <Col>
                <img className="img-in-text" src={image} alt="content"/>
            </Col>
            </Row>
            <Row>
                <h1>{HeaderText(HEADER.header2)}</h1>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                    {ListOfBodyText(BodyText2)}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <ul>
                        <li>
                            <h2>
                               {SmallHeaderText()}
                            </h2>
                        </li>
                        {ListOfItems(LISTTEXT.list2, <HiHeart color="#ED1B34"/>)}
                    </ul>
                </Col>
            </Row>
        </Container>
       </div>
     )
}

export default Omsorg