import React, { Suspense, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { HiBookOpen, HiOutlinePaperClip, HiUserGroup, HiOutlineAcademicCap, HiLibrary } from "react-icons/hi";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Typewriter from "typewriter-effect";
import image from "../images/genre/forskola.jpeg";
const ParticleBackground = React.lazy(() => import("../ParticleComponent"));


function Skola(){
    console.log("SkolaComponent: ", image)
    const HEADER = {   
        header1: "Alla elever ska lyckas",
        header2: "Främja en likvärdig skola",
        header3: "Elever redo för arbets-marknaden",
        header4: "Ta tillbaka den demokratiska kontrollen över skolan",
        header5: "Gör rätt från början"
        }
    
    const LISTTEXT = {
        list1: 
            [{id: 1, text: "Att skolan fokuserar på kärnuppdraget."},
            {id: 2, text: "De som behöver extra stöd ska få det och de som behöver mer utmaningar ska också få det."},
            {id: 3, text: "Att all pedagogisk personal i förskolan och skolan ska kunna svenska."},
            {id: 4, text: "Möjliggöra och finansiera för obehörig skolpersonal att studera hel- eller deltid på betald arbetstid."},
            {id: 5, text: "Att läxhjälp och lovskola erbjuds alla elever."},
            {id: 6, text: "Att skolan ska vara fri från mobbning och annan kränkande behandling."}
            ],
        list2:
            [{id: 1, text: "Att skolans kompensatoriska uppdrag förtydligas."},
            {id: 2, text: "Inrätta bonussystem för de lärare och rektorer som väljer att arbeta i socioekonomiskt utsatta områden och på skolor med de allra tuffaste utmaningarna."},
            {id: 3, text: "Att möjligheten att utfärda vitesbelopp om föräldrar bryter mot skolplikten tillämpas."},
            {id: 4, text: "Att det startas särskilda undervisningsgrupper för elever i behov av särskilt stöd."}
            ],
        list3: 
            [{id: 1, text: "Att kommunen ska satsa på skolor och utbildningar som kan ge jobb."},
            {id: 2, text: "Erbjuda sommarjobb med kollektivavtal till alla elever i gymnasiet."},
            {id: 3, text: "Vid behov erbjuda en offentligt driven gymnasieskola som enbart är engelsktalande."},
            {id: 4, text: "Värna indIviduellt utformade program för personer långt ifrån arbetsmarknaden, framförallt personer med funktionsnedsättning i samverkan med ideella sektorn."},
            {id: 5, text: "Ha skola nära kopplad till arbetsmarknaden."},
            {id: 6, text: "Att skolan är öppen för det omgivande samhället."},
            {id: 7, text: "Värna och utveckla Kulturskolan."}
            ],
        list4: 
            [{id: 1, text: "Stoppa vinstjakten i skolan."},
            {id: 2, text: "Att friskolan ska vara ett komplement till kommunala skolor då kommunen inte kan erbjuda den utbildningen."},
            {id: 3, text: "Erbjuda alla friskolor i grundskolan att ingå i en gemensam antagning med samma regler som gäller för de kommunala skolorna."},
            {id: 4, text: "Arbeta för att närhetsprincipen ska gälla alla skolor i kommunen."},
            {id: 5, text: "Välkomna ett nationellt förbud av religiösa friskolor."}
            ],
        list5: 
            [{id: 1, text: "Arbeta för att införa allmän förskola från två års ålder med ett automatiskt erbjudande om förskoleplats, med möjlighet att tacka nej."},
            {id: 2, text: "Att fler barnskötare och förskollärare ska utbildas och anställas, bland annat genom möjlighet att vidareutbilda sig med lön samtidigt som man kvarstår i anställningen."},
            {id: 3, text: "Utöka tiden för barn till föräldralediga och arbetslösa från dagens 15 timmar till 30 timmar."},
            {id: 4, text: "Att barnomsorg på kvällar, nätter och helger ska finnas för dem som behöver."}
            ],
        }

    const [done, setDone] = useState(false);

    const BodyText1 = () => {return(
        <div>
            <p>Skolan ska vara en plats där alla elever, oavsett bakgrund, ska kunna lyckas och ges redskap för att nå sina drömmar. Vilken skola du studerar på eller vilka förutsättningar du har med dig hemifrån får inte vara avgörande. Tyvärr ser verkligheten inte ut så idag, utan dina föräldrars utbildningsnivå är en av många faktorer som avgör hur väl du lyckas i skolan. Därför ska läxhjälp och lovskola erbjudas alla elever.</p>
            <p>Skolan ska fokusera på kärnuppdraget, det vill säga undervisning. Norrköping har under de senaste åren genomfört ett framgångsrikt arbete där fokus har legat på just skolans kärnuppdrag, vilket har lett till att skolresultaten blivit bättre, även under pandemin. Det är ett arbete vi måste fortsätta.</p>
            <p>Många barn kommer från hem där det talas ett annat modersmål än svenska. För att eleverna ska lära sig svenska är det viktigt att lärarna behärskar språket. Om så behövs, ska de erbjudas utbildning. Elever som behöver ska erbjudas hemspråksundervisning.</p>
            <p>Alla elever och all skolpersonal ska känna sig trygga i skolan. Därför får kränkande behandling som mobbning aldrig accepteras. Åtgärder ska vidtas snabbt och skolan ska arbeta förebyggande.</p>
            <p>Den psykiska ohälsan ökar i alla åldersgrupper. Vårt svar är tydligt, behöver du stöd ska du få det. För att lyckas med detta behöver det finnas en god samverkan mellan bland annat skolan och vården. På varje skola ska det finnas ett aktivt elevhälsoteam som särskilt ska rikta in sig på psykisk ohälsa. </p>
        </div>)};
    
    const BodyText2 = () => {return(
        <div>
            <p>I Norrköping ska det vara tryggt att åldras. Äldreomsorgen ska präglas av hög kvalitet och god kontinuitet. Som vårdbehövande äldre och anhörig ska du känna igen personalen som tar hand om dig. För att lyckas med detta behöver vi höja grundbemanningen genom att anställa fler under- och sjuksköterskor. Villkoren för personalen inom äldreomsorgen måste också bli bättre.</p>
            <p>Undersköterskor ska få ägna sin tid till det man är bäst på, nämligen omvårdnad. Därför vill vi anställa servicepersonal, som kan avlasta omsorgspersonalen vid exempelvis mathantering och städning. Då undersköterskornas löner ligger efter i jämförelse med andra grupper så vill vi under mandatperioden göra en riktad lönesatsning på den gruppen.
            Språk är en nödvändig nyckel för kommunikation. Därför vill vi matcha medarbetare med språkkompetens med omsorgstagarnas behov. Vi vill fortsätta att utveckla arbetet med språktester i svenska för personal i kommunen. Arbetsgivarna ska erbjuda befintlig personal med otillräckliga språkkunskaper utbildning och språkutveckling i svenska språket parallellt med anställningen. </p>
            <p>På våra särskilda boenden ska det finnas meningsfulla aktiviteter. Ingen människa ska behöva hamna mellan stolarna. Insatser för att bryta social isolering och den ofrivilliga ensamheten är viktiga delar för att öka den psykiska hälsan bland våra äldre.</p>
            <p>Kommunens träffpunkter är viktiga. Att möta andra i samma livssituation ger mening i vardagen och bidrar till många sociala kontakter. Genom samverkan med föreningslivet, t.ex. PRO, ska det bli ännu fler mötesplatser för äldre. </p>
            <p>Vi ser positivt på privata utförare, men vill värna kvaliteten för omsorgstagarna och hitta en modell som är långsiktigt hållbar. Därför vill vi avskaffa lagen om valfrihetssystem (LOV) inom hemtjänsten i Norrköpings kommun.</p>
        </div>)};

    const BodyText3 = () => {return(
        <div>
            <p>Norrköping står inför stora infrastruktursatsningar, som den nya höghastighetsjärnvägen Ostlänken, de närmsta åren. De kommer att kräva arbetskraft och kompetens. Vi ska inte förlita oss på extern arbetskraft utan rusta upp vår egen arbetskraft. Därför ska kommunen långsiktigt satsa på utbildningar till yrken som det finns ett stort behov av.</p>
            <p>Mångas första kontakt med arbetsmarknaden sker via sommarjobb. Det ger ungdomar möjlighet att tjäna egna pengar och sysselsätta sig under sommaren. Därför vill vi erbjuda sommarjobb med kollektivavtal till alla elever i gymnasiet.</p>
            <p>Norrköping ska vara en attraktiv etableringsort och många större företag som har utländsk arbetskraft önskar en engelskspråkig gymnasieskola. Om behovet uppstår är vi beredda att erbjuda en offentligt driven gymnasieskola som enbart är engelsktalande.</p>
            <p>Skolan ska vara öppen för det omgivande samhället. Det ska t.ex. ges möjlighet för politiska ungdomsförbund att berätta om sin politik, för försvaret att informera om värnplikten och arbetsmarknadens parter att berätta om sitt arbete.</p>
            <p>Kulturskolan i Norrköping ger alla barn och ungdomar, oavsett var man bor, chans att utveckla sin kreativitet inom musik, drama och konst. Kulturskolans arbete är grunden för att vi framöver har ett rikt kulturliv som gör Norrköping attraktivt att leva i och attraktivt att besöka. Därför ska vi värna och utveckla Kulturskolan.</p>
        </div>
    )}

    const BodyText4 = () => {return(
        <div>
            <p>Vi vill hitta ett sätt att värna friheten att välja skola och samtidigt arbeta för en likvärdig skola utan segregation. Därför vill vi i kommunen erbjuda alla friskolor i grundskolan att ingå i en gemensam antagning med samma regler som gäller för de kommunala skolorna. Dessutom ska närhetsprincipen gälla alla skolor i kommunen.</p>
            <p>Skolan är inte bara en plats för undervisning utan även en plats där elever från tidig ålder får lära sig att respektera varandra med olika bakgrunder, åsikter och religiösa övertygelser. Därför värnar vi skolor där det finns en mångfald av elever och vill se ett förbud av religiösa friskolor.</p>
        </div>
    )}

    const BodyText5 = () => {return(
        <div>
            <p>Förskolan är central för att skapa en bra grund att stå på vad gäller språk, trygghet och kreativitet. Lärandet börjar i förskolan. De allra flesta barn i Sverige går i förskolan vilket gör det möjligt att tidigt se barn som inte mår bra eller är i behov av särskilt stöd. De som inte går i förskola får ofta en längre startsträcka i förhållande till sina jämnåriga kompisar. Det finns många barn i vår kommun som skulle behöva börja på förskolan för att på så sätt hitta språket och lärandet.</p>
        </div>
    )}



 
const HeaderText = (headerText) => {
    return(
    <Typewriter 
        options={{
            cursor: "",
            delay:50
        }}
        onInit={(typewriter)=>{
            typewriter.typeString(headerText).pauseFor(1000).callFunction(NewHeader, true).start()
        }}
        />
        )
}

function NewHeader(){
    setDone(true)
}

function SmallHeaderText(){
    if (done === true){
    return(
    <Typewriter 
        options={{
            cursor: "",
            delay:50
        }}
        onInit={(typewriter)=>{
            typewriter.typeString("Socialdemokraterna Norrköping vill: ").start()
        }}
        />)}
}
const ListOfItems = (list, icon) =>{
    return( 
    <TransitionGroup>
    { list.map((elements) => ( 
        <CSSTransition
            timeout={3000}  
            classNames="fade"
            appear={true}
            in={true}
            key={elements.id}
            >                      
        <li>
            {icon} {elements.text}
        </li>
        </CSSTransition>
    )) }
    </TransitionGroup>)}

const ListOfBodyText = (bodyText) => {
    return(
        <TransitionGroup>
        <CSSTransition
            timeout={500}  
            classNames="fade"
            appear={true}
            in={true}
            >                     
            {bodyText} 
        </CSSTransition>
    </TransitionGroup>)}


return(
    <div className="App-main">
    <Suspense fallback={<div className="pink" />}>  
             <ParticleBackground />
    </Suspense>
    <Container>
        <Row>
            <h1>{HeaderText(HEADER.header1)}</h1>
        </Row>
        <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
                {ListOfBodyText(BodyText1)}
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
                <ul>
                    <li>
                        <h2>
                           {SmallHeaderText()}
                        </h2>
                    </li>
                    {ListOfItems(LISTTEXT.list1, <HiUserGroup color="#ED1B34" />)}
                </ul>
            </Col>
        </Row>
        <Row className="image-container">
        <Col>
             <img className="img-in-text" src={image} alt="content"/>
        </Col>
        </Row>
        <Row>
            <h1>{HeaderText(HEADER.header2)}</h1>
        </Row>
        <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
                {ListOfBodyText(BodyText2)}
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
                <ul>
                    <li>
                        <h2>
                           {SmallHeaderText()}
                        </h2>
                    </li>
                    {ListOfItems(LISTTEXT.list2, <HiOutlinePaperClip color="#ED1B34"/>)}
                </ul>
            </Col>
        </Row>
        <Row>
            <h1>{HeaderText(HEADER.header3)}</h1>
        </Row>
        <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
                {ListOfBodyText(BodyText3)}
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
                <ul>
                    <li>
                        <h2>
                           {SmallHeaderText()}
                        </h2>
                    </li>
                    {ListOfItems(LISTTEXT.list3, <HiOutlineAcademicCap color="#ED1B34" />)}
                </ul>
            </Col>
        </Row>
        <Row>
            <h1>{HeaderText(HEADER.header4)}</h1>
        </Row>
        <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
                {ListOfBodyText(BodyText4)}
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
                <ul>
                    <li>
                        <h2>
                           {SmallHeaderText()}
                        </h2>
                    </li>
                    {ListOfItems(LISTTEXT.list4, <HiLibrary color="#ED1B34" />)}
                </ul>
            </Col>
        </Row>
        <Row>
            <h1>{HeaderText(HEADER.header5)}</h1>
        </Row>
        <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
                {ListOfBodyText(BodyText5)}
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
                <ul>
                    <li>
                        <h2>
                           {SmallHeaderText()}
                        </h2>
                    </li>
                    {ListOfItems(LISTTEXT.list5, <HiBookOpen color="#ED1B34" />)}
                </ul>
            </Col>
        </Row>
    </Container>
   </div>
 )
}

export default Skola