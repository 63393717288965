import React, { Suspense, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Typewriter from "typewriter-effect";
import { HiOutlineSun, HiLightBulb } from "react-icons/hi";
import image from "../images/genre/solpanel.jpeg";
const ParticleBackground = React.lazy(() => import("../ParticleComponent"));
function Klimat(){

    const HEADER = {   
        header1: "Sveriges mest klimatvänliga kommun",
        header2: "Fossilfri el ska säkras"    
        }
    
    const LISTTEXT = {
        list1: 
            [{id: 1, text: "Att såväl cykelnät som pendlarparkeringar byggs ut."},
            {id: 2, text: "Att fler laddstolpar installeras."},
            {id: 3, text: "Se över vilka gator som kan få bussfiler."},
            {id: 4, text: "Öka möjligheterna till återbruk."},
            {id: 5, text: "Att det serveras en mer lokalproducerad, säsongsvarierad och grön kost på kommunens boenden med mera, gärna med inslag av viltkött."},
            {id: 6, text: "Att det ska finnas en omställningsprofil på de etableringar som genomförs."},
            {id: 7, text: "Värna grönytorna i staden, plantera fler träd och förbättra dagvattenhanteringen."},
            {id: 8, text: "Främja ett hållbart bostadsbyggande i hela kommunen."},
            ],
        list2:[{id: 1, text: "Att den lokala framställningen av biogas ska öka och göras mer tillgänglig."},
            {id: 2, text: "Att det byggs vindkraftverk i Norrköping."},
            {id: 3, text: "Att fler fastigheter, såväl kommunala som inom allmännyttan, får solpaneler."}
        ]
        }

    const [done, setDone] = useState(false);

    const BodyText1 = () => {return(
        <div>
            <p>Norrköping ska vara Sveriges mest klimatvänliga kommun. Det ska vara lätt att göra rätt när alla gemensamt bidrar till hållbarhet. I Norrköping ska man enkelt, tryggt och effektivt kunna ta sig med spårvagn, buss, cykel eller till fots. Det kräver att såväl cykelnät som pendlarparkeringar byggs ut.</p>
            <p>Andelen elbilar behöver öka. Det ska vara lätt för den som valt att köra på el att kunna ladda sin bil i Norrköping. Därför ska fler laddstolpar installeras, vid såväl större parkeringar som gatuparkeringar. En översyn ska också genomföras för att se på vilka gator det är möjligt att ha bussfiler.</p>
            <p>Det ska vara enkelt att ta hand om sina sopor och restavfall i Norrköping. Returpunkterna ska vara en given plats för alla Norrköpingsbor att besöka och fler åtgärder för återbruk ska initieras.</p>
            <p>Norrköpings kommun serverar många måltider och det är viktigt att kommunen tar sitt klimatansvar genom att servera en mer lokalproducerad, säsongsvarierad och grön kost, gärna med inslag av viltkött. Norrköping ska också bidra till att minska matsvinnet under mandatperioden.</p>
            <p>Klimatomställningen är en möjlighet till nya jobb för Norrköping. Att SMHI:s klimatforskning, Visualiseringscenters interaktionsteknologi och Linköpings universitets tekniska utbildningar finns samlade i samma stad skapar en grund för spännande samarbeten på klimatområdet.</p>
            <p>Norrköpings goda läge med bra kommunikationer gör Norrköping väldigt attraktivt för att dra till sig företag. Dessa etableringar ska ha en omställningsprofil. Ostlänkens färdigställande kommer öka möjligheterna ytterligare. Genom kortare restider knyts Norrköping närmare Stockholm.</p>
            <p>För att möta klimatförändringen måste flera klimatanpassningar göras i kommunen. Bland annat måste vi värna grönytorna i staden och plantera fler träd. Dessa binder vatten vid kraftiga regn och sänker temperaturen vid extrem hetta. Ett hållbart bostadsbyggande ska främjas, med trähus, gröna tak och mer genomsläppliga markmaterial.</p>
        </div>)};
    
    const BodyText2 = () => {return(
        <div>
            <p>Utbyggnaden av fossilfri energi är en högt prioriterad fråga. Den lokala framställningen av biogas ska öka och göras mer tillgänglig för Norrköpingsborna. Biogas är bra för klimatet, stärker lantbruket och ger mindre övergödning.</p>
            <p>God eltillgång är en grundläggande förutsättning för kommunens och det lokala näringslivets utveckling. Överföringskapaciteten måste förbättras samtidigt som den lokala elproduktionen byggs ut på ett hållbart sätt. Vi vill se att det byggs vindkraftverk i Norrköping samt att fler fastigheter, såväl kommunala som inom allmännyttan, får solpaneler och blir energisnåla.</p>
        </div>)};


 
    const HeaderText = (headerText) => {
        return(
        <Typewriter 
            options={{
                cursor: "",
                delay:50
            }}
            onInit={(typewriter)=>{
                typewriter.typeString(headerText).pauseFor(1000).callFunction(NewHeader, true).start()
            }}
            />
            )
    }

    function NewHeader(){
        setDone(true)
    }

    function SmallHeaderText(){
        if (done === true){
        return(
        <Typewriter 
            options={{
                cursor: "",
                delay:50
            }}
            onInit={(typewriter)=>{
                typewriter.typeString("Socialdemokraterna Norrköping vill: ").start()
            }}
            />)}
    }
    const ListOfItems = (list, icon) =>{
        return( 
        <TransitionGroup>
        { list.map((elements) => ( 
            <CSSTransition
                timeout={3000}  
                classNames="fade"
                appear={true}
                in={true}
                key={elements.id}
                >                      
            <li>
                {icon} {elements.text}
            </li>
            </CSSTransition>
        )) }
        </TransitionGroup>)}

    const ListOfBodyText = (bodyText) => {
        return(
            <TransitionGroup>
            <CSSTransition
                timeout={500}  
                classNames="fade"
                appear={true}
                in={true}
                >                     
                {bodyText} 
            </CSSTransition>
        </TransitionGroup>)}

    
    return(
        <div className="App-main">
        <Suspense fallback={<div className="pink" />}>  
             <ParticleBackground />
        </Suspense>
        <Container>
            <Row>
                <h1>{HeaderText(HEADER.header1)}</h1>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                    {ListOfBodyText(BodyText1)}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <ul>
                        <li>
                            <h2>
                               {SmallHeaderText()}
                            </h2>
                        </li>
                        {ListOfItems(LISTTEXT.list1, <HiOutlineSun color="#ED1B34" />)}
                    </ul>
                </Col>
            </Row>
            <Row className="image-container">
            <Col>
                <img className="img-in-text" src={image} alt="content"/>
            </Col>
            </Row>
            <Row>
                <h1>{HeaderText(HEADER.header2)}</h1>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                    {ListOfBodyText(BodyText2)}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <ul>
                        <li>
                            <h2>
                               {SmallHeaderText()}
                            </h2>
                        </li>
                        {ListOfItems(LISTTEXT.list2, <HiLightBulb color="#ED1B34"/>)}
                    </ul>
                </Col>
            </Row>
        </Container>
       </div>
     )
}

export default Klimat