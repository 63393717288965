import React, { Suspense, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { HiOutlineSupport, HiLockClosed, HiEye } from "react-icons/hi";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Typewriter from "typewriter-effect";
import image from "../images/genre/ordningsvakter.jpeg";
const ParticleBackground = React.lazy(() => import("../ParticleComponent"));

function Trygghet(){

    const HEADER = {   
        header1: "Alla ska känna sig trygga på Norrköpings gator och torg",
        header2: "Sätt fokus på det förebyggande arbetet" ,
        header3: "Levande kultur och fritid för en starkare samman-hållning"   
        }
    
    const LISTTEXT = {
        list1: 
            [{id: 1, text: "Öka kameraövervakningen på allmänna platser."},
            {id: 2, text: "Öka den synliga närvaron av poliser och fältassistenter."},
            {id: 3, text: "Utöka den kommunala vaktorganisationen."},
            ],
        list2:[{id: 1, text: "Förhindra nyrekrytering av unga kriminella och bekämpa förekomsten av droger genom att främja samverkan mellan skola, socialtjänst, polis och fritidsverksamhet."},
            {id: 2, text: "Stärka avhopparverksamheten genom att arbeta mer motiverande och uppsökande."},
            {id: 3, text: "Att hemlösa ges möjlighet till bostad utan krav på nykter- och drogfrihet."},
            {id: 4, text: "Förbättra samverkan kring missbruksvården mellan regionen och kommunen."},
            {id: 5, text: "Utöka det kommunala arbetet mot mäns våld mot kvinnor och stötta kvinnorna som lever i våld att göra sig fria och leva självständigt."}
        ],
        list3:[{id: 1, text: "Värna kulturinstitutionerna och det fria kulturlivet."},
            {id: 2, text: "Ge alla barn och unga i Norrköping möjlighet till aktivitet efter skolan för att få en meningsfull och aktiv fritid."},
            {id: 3, text: "Bygga in Himmelstalunds bandyplan och modernisera konstfrysningsanläggningen."},
            {id: 4, text: "Öka föreningsbidragen."}]
        }

    const [done, setDone] = useState(false);

    const BodyText1 = () => {return(
        <div>
            <p>Alla vet att Socialdemokraterna förespråkar trygghet i arbetslivet och välfärden. Självklart gäller vår trygghetssträvan även tryggheten på gator och torg. Det är grundläggande för ett samhälle som bygger på jämlikhet och frihet för var och en. Norrköping ska vara en trygg kommun för alla.</p>
            <p>Att få till en ändamålsenlig kamerabevakning på strategiskt viktiga platser är avgörande i det trygghetsskapande arbetet. När vi ställs inför valet att skydda den personliga integriteten genom att kamerorna stängs av eller att kamerorna får vara påslagna för att förhindra brott. Då är valet enkelt. Kameror förebygger brott och därmed skyddar de i själva verket den personliga integriteten. Det finns inget mer kränkande för en persons integritet än att bli rånad eller utsatt för våld.</p>
            <p>Allt för ofta skadas och dödas människor på gator och torg av skjutningar och sprängningar. Vi kan inte blunda för att det förekommer narkotikahandel, rån och skjutningar på öppen gata. Nu är det viktigare än någonsin att oavsett var man bor så ska det vara fler poliser ute där människor rör sig. Då ökar förutsättningarna att få koll på hur situationen ser ut i brottsutsatta områden, vilka individer som står för kriminaliteten och hur vi kan förebygga. Vi behöver poliser som finns på plats och bygger relationer med invånarna. De kommunalt upphandlade väktarna utgör ett viktigt komplement som vi vill se mer av.</p>
        </div>)};
    
    const BodyText2 = () => {return(
        <div>
            <p>Vi vill se ett Norrköping fritt från droger. Ungdomars uppväxtmiljöer ska vara drogfria och trygga. Därför krävs ökad samverkan mellan polisen, kommunen och regionen. Det är också viktigt att involvera föräldrarna som med rätt kunskap kan ha stor betydelse. Vi kan inte acceptera att droger förekommer i skolor, på gator eller torg.</p>
            <p>Vi vill avskaffa hemlösheten i Norrköping. Då då flesta hemlösa är fast i missbruk är vi beredda att vända på varje sten för att de ska kunna lämna såväl gatan som missbruket. Därför har vi börjat ta bort kraven på nykter- och drogfrihet för eget boende. Det ger fler möjlighet att börja resan mot en ordnad tillvaro.</p>
            <p>Samsjuka personer med psykisk ohälsa och narkotikamissbruk riskerar att bollas fram och tillbaka mellan socialtjänstens missbruksvård och regionens vård. De misstas för att enbart ha ett missbruk när missbruket har startat genom t.ex. självmedicinering på grund av psykisk ohälsa. Allt för många faller tillbaka i sitt missbruk, vilket måste motverkas.</p>
            <p>Vi vill förbättra samverkan mellan regionen och kommunen när det gäller psykiatrisk vård och missbruksvård. Samtidigt finns det skäl att på sikt göra en tydligare ansvarsfördelning där regionen ansvarar för all missbruksvård medan socialtjänsten fokuserar på stabiliserande insatser. En bra samverkan kommer däremot alltid vara viktig.</p>
        </div>)};

    const BodyText3 = () => {return(
        <div>
            <p>För ett tryggt Norrköping behövs sammanhållning. Tillit och trygghet skapas när människor möts. Kulturen har ett egenvärde, men är också viktig för att vidga vyer, ge utrymme för kreativitet och föra samman människor med olika bakgrunder. Lika viktig är idrotten, från allas möjlighet att röra på sig till elitidrottare vars framgångar vi är många som gläds åt och inspireras av. Vi vill också att ännu fler barn och unga får möjlighet att idrotta och med det goda vanor. Detta kräver engagerade idrottsföreningar och bra anläggningar.</p>
            <p>Vi vill se fler idrottsplatser, utegym, föreningslokaler och andra mötesplatser. För att säkra möjligheten att erbjuda bra isytor under vintern för skridskoåkning, konståkning, bandy och ishockey vill vi bygga in Himmelstalunds bandyplan och modernisera konstfrysanläggningen. Där det är möjligt ser vi gärna föreningsdrift av anläggningarna. Vi vill också öka föreningsbidragen.</p>
        </div>)};   

 
    const HeaderText = (headerText) => {
        return(
        <Typewriter 
            options={{
                cursor: "",
                delay:50
            }}
            onInit={(typewriter)=>{
                typewriter.typeString(headerText).pauseFor(1000).callFunction(NewHeader, true).start()
            }}
            />
            )
    }

    function NewHeader(){
        setDone(true)
    }

    function SmallHeaderText(){
        if (done === true){
        return(
        <Typewriter 
            options={{
                cursor: "",
                delay:50
            }}
            onInit={(typewriter)=>{
                typewriter.typeString("Socialdemokraterna Norrköping vill: ").start()
            }}
            />)}
    }
    const ListOfItems = (list, icon) =>{
        return( 
        <TransitionGroup>
        { list.map((elements) => ( 
            <CSSTransition
                timeout={3000}  
                classNames="fade"
                appear={true}
                in={true}
                key={elements.id}
                >                      
            <li>
                {icon} {elements.text}
            </li>
            </CSSTransition>
        )) }
        </TransitionGroup>)}

    const ListOfBodyText = (bodyText) => {
        return(
            <TransitionGroup>
            <CSSTransition
                timeout={500}  
                classNames="fade"
                appear={true}
                in={true}
                >                     
                {bodyText} 
            </CSSTransition>
        </TransitionGroup>)}

    
    return(
        <div className="App-main">
        <Suspense fallback={<div className="pink" />}>  
             <ParticleBackground />
        </Suspense>
        <Container>
            <Row>
                <h1>{HeaderText(HEADER.header1)}</h1>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                    {ListOfBodyText(BodyText1)}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <ul>
                        <li>
                            <h2>
                               {SmallHeaderText()}
                            </h2>
                        </li>
                        {ListOfItems(LISTTEXT.list1, <HiEye color="#ED1B34" />)}
                    </ul>
                </Col>
            </Row>
            <Row className="image-container">
            <Col>
                <img className="img-in-text" src={image} alt="content"/>
            </Col>
            </Row>
            <Row>
                <h1>{HeaderText(HEADER.header2)}</h1>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                    {ListOfBodyText(BodyText2)}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <ul>
                        <li>
                            <h2>
                               {SmallHeaderText()}
                            </h2>
                        </li>
                        {ListOfItems(LISTTEXT.list2, <HiLockClosed color="#ED1B34"/>)}
                    </ul>
                </Col>
            </Row>
            <Row>
                <h1>{HeaderText(HEADER.header3)}</h1>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                    {ListOfBodyText(BodyText3)}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <ul>
                        <li>
                            <h2>
                               {SmallHeaderText()}
                            </h2>
                        </li>
                        {ListOfItems(LISTTEXT.list3, <HiOutlineSupport color="#ED1B34"/>)}
                    </ul>
                </Col>
            </Row>
        </Container>
       </div>
     )
}

export default Trygghet