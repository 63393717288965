import React, { Suspense } from "react";
import { Col, Row, Container } from "reactstrap";
import image from "../images/genre/olle-maria.jpeg";
const ParticleBackground = React.lazy(() => import("../ParticleComponent"))

function Inledning(){
    return(
        <div>    
        
        <div className="App-main">
        <Suspense fallback={<div className="pink" />}>  
             <ParticleBackground />
        </Suspense>
        <Container>
        <Row className="image-container">
            <Col>
                <img className="img-in-text" src={image} alt="content"/>
            </Col>
        </Row>
        <Row>
            <h1>Nystart för Norrköping</h1>
        </Row>
        <Row>
            <Col>
                <p><b>Socialdemokraternas valprogram innehåller 86 förslag för en trygg, jämlik och hållbar kommun och lägger grunden för en nystart för Norrköping.</b></p>
                <p>Spårvagnarna som varit klimatsmarta i mer än 100 år, industrilandskapet med universitetet mitt i stan, omvandlingen av inre hamnen. Skärgården, Kolmårdsskogarna, pappersbruken som ger jobb. Fotbollen, basketen, ridsporten och mycket annan idrott som skapar spänning och sammanhållning. En framstående kulturstad med bredd och spets, där offentlig teater och symfoniorkester stärks av Kulturskolan, museer samt fria musik- och teatergrupper.</p>
                <p>Uppräkningen kan fortsätta länge. Det finns många skäl att älska Norrköping, människorna och platsen där vi bor. Byggkranarna som syns runt om i kommunen, i såväl Hageby, Svärtinge som Krokek talar sitt tydliga språk. Norrköping växer i alla riktningar och blir ett hem för allt fler.</p>
                <p>Samtidigt är arbetslösheten för hög. Segregationen gör det svårt för människor att komma in i samhället. Vi är också oroade över våldet och de allt grövre brotten. Pandemin har varit hänsynslös. Många har förlorat någon de älskat. De som arbetar inom omsorgen har jobbat länge under hård press. Det allt mer extrema vädret har påmint oss om att vi måste öka takten i klimatomställningen.</p>
                <p>Norrköping behöver en nystart. Den nystarten vill vi socialdemokrater leda och ta ansvar för. Vi förstår att de problem som Norrköpingsborna möter varje dag kräver mer än det vi gjort hittills.</p>
                <p>I detta valprogram lyfter vi fram våra viktigaste prioriteringar. Målsättningen är känd sedan tidigare. Norrköping ska bli tryggt, jämlikt och hållbart. För att komma dit har vi tagit fram 86 förslag.</p>
                <p>För oss socialdemokrater är den röda tråden i all politik att bekämpa orättvisor och minska klyftor. Det kräver ett starkt samhälle där vi hjälps åt. Det är viktigare än marknadsexperiment och skattesänkningar.</p>
                <p><b>Alla elever ska lyckas.</b> En bra skola är bästa vaccinet mot att det går dåligt senare i livet. Därför har skolan högsta prioritet. Det har också märkts genom att allt fler är behöriga till gymnasiet och allt fler går ut gymnasiet med godkända betyg. Nu måste vi hålla fast vid det vi gjort rätt och göra mer av det. En viktig del är ordning, reda och studiero i klassrummet. Lärande och goda resultat ska vara i fokus. Läxhjälp och lovskola ska erbjudas alla elever.</p>
                <p><b>Behöver du stöd ska du få det.</b> En omsorg och sjukvård av hög kvalitet som finns där när den behövs kräver att de som utför den får bra villkor. Vi vill höja grundbemanningen i äldreomsorgen genom att anställa fler under- och sjuksköterskor med rätt kompetens, göra riktade lönesatsningar till undersköterskor, ha fler karriärvägar inom omsorgsyrkena och stärka möjligheterna till delaktighet för personalen.</p>
                <p><b>Utanförskapet ska brytas. Alla ska inkluderas.</b> För den som tar emot bidrag ska det krävas en motprestation, t.ex. språkundervisning eller deltagande i arbetsmarknadsinsatser. Vi behöver också arbeta mer uppsökande för att nå fler. Nya asylsökande ska inte placeras i områden med stort utanförskap. I slutet av mandatperioden ska inget område i Norrköping finnas med på polisens lista över utsatta områden.</p>
                <p><b>Alla som kan jobba ska jobba.</b> De senaste åren har arbetslösheten minskat snabbare i Norrköping än i resten av landet. Den utvecklingen ska förstärkas. Det kräver att kommunen arbetar tillsammans med såväl myndigheter, näringsliv som fackföreningar.</p>
                <p>Vi ser redan att många nya jobb kommer inom miljö och klimat.Vi har ett bra utgångsläge såväl geografiskt som med ett innovativt näringsliv och kraftcentrum som universitetet, Visualiseringscentrum och SMHI. Vi ser stora möjligheter till företagsetableringar med omställningsprofil. Det kommer kräva mer fossilfri el som vindkraft och solenergi. Vi lovar jobba hårt. <b>Norrköping ska bli Sveriges mest klimatvänliga kommun.</b></p>
                <p><b>Alla ska känna sig trygga på Norrköpings gator och torg.</b> Därför vill stärka den kommunala vaktorganisationen och sätta upp kameror där de gör nytta. Vi vill se ett Norrköping fritt från droger. För att nå dit krävs förbättrad missbruksvård, bostad först och stärkt förebyggande arbete i samverkan mellan skola, socialtjänst, polis och fritidsverksamhet. Kulturen och idrottsföreningarna ska ha goda möjligheter att ge barn och unga en meningsfull fritid.</p>
                <p>För att klara skolan, vården och omsorgen, men också nödvändiga investeringar i vägar, cykelbanor och järnvägar krävs en ansvarsfull ekonomi. Vi behöver använda oss av digitalisering och nya arbetssätt för att nyttja våra resurser på bästa sätt.</p>
                <p>Hela Norrköping ska leva. Oavsett var du bor i kommunen ska du ha tillgång till bra utbildning och jobb. Vi vill också se ett ökat bostadsbyggande både i och utanför tätorten. Vi går till val på att söka största möjliga stöd för socialdemokratisk politik.</p>
            </Col>
        </Row>
        </Container>
       </div>
       </div>
    )
}

export default Inledning