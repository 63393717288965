import React, { useState } from 'react';
import { Row, Col, Nav, Navbar, NavLink, NavItem, Collapse, Container, NavbarToggler, UncontrolledDropdown } from 'reactstrap';
import Typewriter from 'typewriter-effect';
import { GiHamburgerMenu } from 'react-icons/gi';

function Header(props){
    console.log(window.innerWidth)
    const isWide = () => {
        return window.innerWidth > 760
    }
    const [isActive, setActive] = useState(isWide);

    const toggleActive = () => {
        setActive(!isActive);
    }

   

    const selected = {
        activeObject: null,
        objects: [{id:1, link: "/", text:"VALPROGRAM"}, 
                {id:2, link:"/omsorg-och-sjukvard", text: "OMSORG"}, 
                {id:3, link:"/jobben", text: "JOBB"}, 
                {id:4, link: "/trygghet", text:"TRYGGHET"}, 
                {id:5,link:"/skola", text:"SKOLA"}, 
                {id:6, link: "/hallbarhet", text:"HÅLLBARHET"}, 
                {id:7, link:"/integration", text:"INTEGRATION"}]
    };

    function toggleStyle(index){
        if (selected.objects[index].link === props.location){
            return "nav-item-selected"
        }else{
            return "nav-item-not-selected";
            
        }
    }

    return(
        <div className="App-header">
            <Container>
            <Row>
                <Col className="header-text" xs={12} sm={12} md={6} lg={6}>
                    <Typewriter 
                    options={{
                        cursor: "",
                        delay:50
                    }}
                    onInit={(typewriter)=>{
                        typewriter.typeString("Vårt Norrköping kan bättre").start()
                    }}
                    />
                </Col>
                 <Col className="nav-container">
                    <Navbar expand="md">
                    <Container className='absolute'>
                        <NavbarToggler onClick={toggleActive} className='mr-2 toggler'>
                            <GiHamburgerMenu size={30} color="#fff" />
                        </NavbarToggler>
                        <Collapse id="responsive-navbar-nav"  isOpen={isActive} >
                        <Nav className="nav-text" navbar>
                            { selected.objects.map((elements, index)=> (
                                <NavItem 
                                className={toggleStyle(index)}
                                key={index} //onClick={() => toggleSelected(index)}
                                >
                                    <NavLink 
                                    href={elements.link}
                                    >
                                        {elements.text}
                                    </NavLink>
                                </NavItem>
                            ))
                            }
                            <UncontrolledDropdown nav inNavbar/>
                        </Nav>
                        </Collapse>
                    </Container>
                    </Navbar>
                </Col>            
            </Row>
            </Container>
        </div>
    )
}
export default Header