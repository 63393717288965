import React, { Suspense, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { HiUsers } from "react-icons/hi";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Typewriter from "typewriter-effect";
import image from "../images/genre/integration.jpeg";
const ParticleBackground = React.lazy(() => import("../ParticleComponent"))


function Integration(){
    const HEADER = {   
        header1: "Utanförskapet ska brytas - alla ska inkluderas"
        }
    
    const LISTTEXT = {
        list1: 
            [{id: 1, text: "Kräva motprestation vid  bidrag, t.ex. språkundervisning eller deltagande i arbetsmarknadsinsatser, som inte underminerar den svenska modellen med kollektivavtal."},
            {id: 2, text: "Utöka program där arbete och praktik kombineras med språkundervisning."},
            {id: 3, text: "Att inga asylsökande placeras i utanförskapsområden."},
            {id: 4, text: "Ha mer av förebyggande insatser för barn och unga i utanförskapsområden."},
            {id: 5, text: "Tidigt sätta in insatser när barn far illa eller uppvisar destruktivt beteende."},
            {id: 6, text: "Att myndigheter ska vara närvarande i utanförskapsområden."},
            {id: 7, text: "Att Hyresbostäder ska bygga fler billiga hyresrätter, både i och utanför tätorten."},
            {id: 8, text: "Utöka samverkan mellan kommunen, andra myndigheter och fackförbund för att stävja missbruk och välfärdsfusk."},
            {id: 9, text: "Arbeta uppsökande kring arbetsmarknadsinsatser i utanförskapsområden."},
            {id: 10, text: "Arbeta aktivt för att få fler långtidsarbetslösa kvinnor i utanförskapsområden i arbete eller annan sysselsättning."},
            {id: 11, text: "Att inget av Norrköpings områden ska finns med på polisens lista över utsatta områden."},
            ]
        }

    const [done, setDone] = useState(false);

    const BodyText1 = () => {return(
        <div>
            <p>Norrköping går att förändra. Vi har organiserad brottslighet, svart arbetsmarknad, svart bostadsmarknad och har haft en stor migration. Vi har varit naiva och tagit det starka Norrköping för givet. Vi har däremot också goda exempel på när samhället samverkar och lyckas bryta den organiserade brottsligheten på arbetsmarknaden.</p>
            <p>Kommunen och andra myndigheter som exempelvis Skatteverket, Polisen och Arbetsmiljö-verket utför tillsammans riktade insatser, där misstanke om brott finns. Tack vare denna samverkan är svartklubbar nästintill obefintliga i Norrköping. Vi vill se mer av sådan samverkan för att knäcka organiserad brottslighet och välfärdsfusk.</p>
            <p>Fri arbetskraftsinvandring, fri bosättning och friskolereformen leder till ökade klyftor, fattigdom och kriminalitet. Vi får inte glömma bort hur viktigt det är med bra skola, bra omsorg och bra bostadsområden.</p>
            <p>Vi ska bygga ett gemensamt Norrköping där ingen kan eller tvingas stå utanför. Vi ska ha ett fokus under mandatperioden på utanförskapsområden. Göra ett samlat krafttag för att motverka utanförskap genom en skola med ordning och reda, öka tryggheten, skapa trivsamma boendemiljöer, höja utbildningsnivån och få fler i jobb.</p>
            <p>Människor som kräver sin rätt ska också utföra sin plikt. Vid bidrag ska det ställas krav på att människor deltar i språkundervisning eller arbetsmarknadsinsatser för att snabbt komma ut på arbetsmarknaden. Vi behöver nå ut till fler människor som lever i utanförskapsområden och som står långt ifrån arbetsmarknaden genom aktivt uppsökande arbete för att motivera fler att ta sig ur bidragsberoende.</p>
            <p>En stark allmännytta som erbjuder olika sorters boende och en stark hyresgäströrelse som kan driva sina medlemmars intressen ska värnas. Vi vill att det byggs fler billiga hyresrätter. För att minska segregationen ska de vara utspridda i hela kommunen. Detta ska vara något helt annat än så kallad social housing. Allt som byggs ska vara av god kvalitet.</p>
            <p>Kommunen har redan initierat ett arbete med att se över oriktiga boendeförhållanden genom samverkan med Folkbokföringen. Syftet är att identifiera trångboddhet och välfärdsfusk i kommunen. Vi vill fullfölja det arbetet.</p>
            <p>Vi socialdemokrater vet att det finns strukturer i samhället som gör det svårare för vissa grupper att lyckas än andra. För att i ett tidigt skede fånga upp unga i socioekonomiskt utsatta områden behöver vi arbeta mer uppsökande för att tidigt identifiera och bryta destruktiva mönster. Här krävs samverkan mellan skola, polis, fritidsverksamhet och socialtjänst, men också med föreningslivet.</p>
        </div>)};


 
    const HeaderText = (headerText) => {
        return(
        <Typewriter 
            options={{
                cursor: "",
                delay:50
            }}
            onInit={(typewriter)=>{
                typewriter.typeString(headerText).pauseFor(1000).callFunction(NewHeader, true).start()
            }}
            />
            )
    }

    function NewHeader(){
        setDone(true)
    }

    function SmallHeaderText(){
        if (done === true){
        return(
        <Typewriter 
            options={{
                cursor: "",
                delay:50
            }}
            onInit={(typewriter)=>{
                typewriter.typeString("Socialdemokraterna Norrköping vill: ").start()
            }}
            />)}
    }
    const ListOfItems = (list, icon) =>{
        return( 
        <TransitionGroup>
        { list.map((elements) => ( 
            <CSSTransition
                timeout={3000}  
                classNames="fade"
                appear={true}
                in={true}
                key={elements.id}
                >                      
            <li>
                {icon} {elements.text}
            </li>
            </CSSTransition>
        )) }
        </TransitionGroup>)}

    const ListOfBodyText = (bodyText) => {
        return(
            <TransitionGroup>
            <CSSTransition
                timeout={500}  
                classNames="fade"
                appear={true}
                in={true}
                >                     
                {bodyText} 
            </CSSTransition>
        </TransitionGroup>)}

    
    return(
        <div className="App-main">
        <Suspense fallback={<div className="pink" />}>  
             <ParticleBackground />
        </Suspense> 
        <Container>
            <Row className="image-container">
            <Col>
                <img className="img-in-text" src={image} alt="content"/>
            </Col>
            </Row>
            <Row>
                <h1>{HeaderText(HEADER.header1)}</h1>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                    {ListOfBodyText(BodyText1)}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <ul>
                        <li>
                            <h2>
                               {SmallHeaderText()}
                            </h2>
                        </li>
                        {ListOfItems(LISTTEXT.list1, <HiUsers color="#ED1B34" />)}
                    </ul>
                </Col>
            </Row>
        </Container>
       </div>
     )
}

export default Integration