import { Row, Col, Container } from "reactstrap";
import { HiBriefcase, HiTruck } from "react-icons/hi";
import React, { Suspense, useState } from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Typewriter from "typewriter-effect";
import image from "../images/genre/industri.jpeg";
const ParticleBackground = React.lazy(() => import("../ParticleComponent"))

const Jobb = () =>{
    const HEADER = {   
        header1: "Alla som kan jobba ska jobba",
        header2: "Stärk arbetstagarna"    
        }
    
    const LISTTEXT = {
        list1: 
            [{id: 1, text: "Skapa branschråd mellan näringslivet, kommunen, skolan och fackföreningar."},
            {id: 2, text: "Införa lärlingssystem inom kommunen i samverkan med arbetsmarknadens parter."},
            {id: 3, text: "I anbudsförfarandet ha krav på att ta emot fler praktikanter/lärlingar."},
            {id: 4, text: "Få fler yrkesutbildningar inom rimligt pendlingsavstånd."},
            {id: 5, text: "Hitta lokala lösningar för möjligheten att deltidsstudera vid sidan av deltagande inom jobb- och utvecklingsgarantin."},
            {id: 6, text: "Ge kommunledningen ett tydligt ansvar att jobba med näringslivet."},
            {id: 7, text: "Erbjuda behovsstyrd service för företagare som är tillgänglig dygnet runt."},
            {id: 8, text: "Ställa krav på kollektivavtal vid markanvisning."},
            {id: 9, text: "Stärka samverkan mellan universitetet, kommunen och näringslivet."},
            {id: 10, text: "Verka för ett övertagande av vägsamfälligheterna i kommunens prioriterade utvecklingsorter."}
            ],
        list2:[
            {id: 1, text: "Att krav på kollektivavtal och övertagande av personal vid byte av utförare, ställs vid alla Norrköpings kommuns upphandlingar."},
            {id: 2, text: "Följa upp efterlevnaden av kollektivavtalen vid upphandlingar."},
            {id: 3, text: "Att varje enhet och medarbetare ska kunna påverka sitt schema så det är anpassat till verksamheten och arbetsmiljön."},
            {id: 4, text: "Genom löneanalys avsätta medel för att jämna ut löneskillnader."},
            {id: 5, text: "Erbjuda ett generationsväxlingsprogram för att tillvarata erfarna medarbetares kompetens och underlätta för fler att stanna längre i arbetslivet."},
            {id: 6, text: "Utreda inrättande av ett handledarcentrum liknande det som finns i Linköping."}
            ]
        }

    const [done, setDone] = useState(false);

    const BodyText1 = () => {return(
        <div>
            <p>Många nya arbeten skapas hela tiden, så många att näringslivet känner oro för den kompetensbrist som råder. Denna brist på vissa kompetenser gäller även kommunens behov av anställda. Samtidigt är allt för många arbetslösa.</p>
            <p>Genom särskilda utbildningsinsatser som tar vara på företagens behov och samtidigt skapa framtidshopp hos Norrköpingsborna så kan vi lösa en del av behovet på arbetskraft. Framgångsfaktorn kommer vara att matcha individer mot dagens och morgondagens arbete.</p>
            <p>Genom att skapa branschråd mellan näringslivet, kommunen, skolan och fackföreningar så kommer bilden av framtida kompetens att tydliggöras på ett bättre sätt och vi kan då utforma de insatser som behövs. </p>
            <p>Att införa lärlingssystem eller liknande inom kommunens verksamheter skulle bidra till att fler får upp ögonen för yrken som finns inom det offentliga.</p>
            <p>Alla som kan jobba ska jobba. Det är en förutsättning för en välfärd av hög kvalitet, ökad jämlikhet, förbättrad folkhälsa och ett samhälle som håller ihop. För den som står långt från arbetsmarknaden behövs en sammanhållen kedja av insatser. Grunden är svenska språket. Portalen i Hagebys arbete med mentorer är ett gott exempel vi gärna ser mer av.</p>
            <p>Vuxenutbildning och folkbildning, i olika former, har stor betydelse. Yrkeshögskolan ska finnas nära företagen och dess behov. Samverkan genom Samordningsförbundet mellan försäkringskassan, arbetsförmedlingen, kommunen och regionen behöver utvecklas.</p>
            <p>Norrköping har bra förutsättningar genom sitt geografiska läge och goda kommunikationer med hamn, järnväg och motorvägar. Till det kommer universitetet, ett starkt näringsliv och ett innovativt klimat som bland annat främjas genom Norrköpings Science Park. Etableringen av Ostlänken kommer stärka detta ännu mer. Samtidigt måste vi göra mesta möjliga av de förutsättningarna.</p>
            <p>Vi vill vara den kommun företag väljer. Det ska finnas god tillgång till mark. Det behöver vara mindre känsla av myndighet och mer av service i kommunens förhållande till näringslivet. Genom digitalisering kan kommunen vara öppen dygnet runt. Samtidigt vill vi att det ska vara företagsetableringar som ger många jobb och gärna över tid. Självklart ska större företag som etablerar sig i kommunen ha kollektivavtal och schyssta villkor.</p>
            <p>Campus Norrköping ska växa och vi vill bättre ta vara på möjligheterna med universitetet. Vi vill se fler sökande och att fler studenter stannar kvar i Norrköping efter studierna. Vi ser gärna ökad samverkan mellan näringslivet, kommunen och universitetet för att bättre matcha företagens behov och ta vara på nya innovationer.</p>
            <p>Om Norrköpings kommun ska kunna nå sin fulla potential, så måste förutsättningar för tillväxt finnas i hela kommunen avseende såväl jobb som bostäder. Kommunen bör därför ha så stor rådighet som möjligt över infrastruktur och allmän plats i de viktigaste utvecklingsorterna i Norrköping. Därför bör ett kommunalt övertagande av vägsamfälligheterna i kommunens prioriterade utvecklingsorter utredas vidare.</p>
        </div>)};
    
    const BodyText2 = () => {return(
        <div>
            <p>Kommunen ska ställa krav på kollektivavtal och andra sociala krav vid alla sina upphandlingar och därmed säkerställa att konkurrensneutraliteten inte åsidosätts. Arbetsmiljön måste utformas så att människor orkar arbeta fram till pension. Mänsklig hållbarhet är nödvändig för att vi ska ha ett fungerande samhälle. Det är också viktigt att efterlevnaden av kollektivavtalen följs upp.</p>
            <p>Individer i samhället måste känna trygghet genom hela livet. Trygghet skapas genom goda förutsättningar för utbildning och ett arbete. Trygghet skapas även om vi som arbetsgivare i kommunen bidrar till en god arbetsmiljö där medarbetare känner tillit och delaktighet. Vi har redan avskaffat delade turer och kommer arbeta för ökad delaktighet i att bestämma sitt schema. För att säkerställa jämställda löner behöver en löneanalys göras. Vi vill också införa ett generationsväxlingsprogram.</p>
            <p>Många företag och verksamheter vill bidra till att föra människor närmare arbete, men saknar resurser för det. Därför vill vi utreda möjligheten att inrätta ett handledarcentrum med handledare som aktivt handleder praktikanter för att underlätta både för individen och arbetsplatsen.</p>
        </div>)};


 
    const HeaderText = (headerText) => {
        return(
        <Typewriter 
            options={{
                cursor: "",
                delay:50
            }}
            onInit={(typewriter)=>{
                typewriter.typeString(headerText).pauseFor(1000).callFunction(NewHeader, true).start()
            }}
            />
            )
    }

    function NewHeader(){
        setDone(true)
    }

    function SmallHeaderText(){
        if (done === true){
        return(
        <Typewriter 
            options={{
                cursor: "",
                delay:50
            }}
            onInit={(typewriter)=>{
                typewriter.typeString("Socialdemokraterna Norrköping vill: ").start()
            }}
            />)}
    }
    const ListOfItems = (list, icon) =>{
        return( 
        <TransitionGroup>
        { list.map((elements) => ( 
            <CSSTransition
                timeout={3000}  
                classNames="fade"
                appear={true}
                in={true}
                key={elements.id}
                >                      
            <li>
                {icon} {elements.text}
            </li>
            </CSSTransition>
        )) }
        </TransitionGroup>)}

    const ListOfBodyText = (bodyText) => {
        return(
            <TransitionGroup>
            <CSSTransition
                timeout={500}  
                classNames="fade"
                appear={true}
                in={true}
                >                     
                {bodyText} 
            </CSSTransition>
        </TransitionGroup>)}

    
    return(
        <div className="App-main">
        <Suspense fallback={<div className="pink" />}>  
             <ParticleBackground />
        </Suspense>
        <Container>
            <Row>
                <h1>{HeaderText(HEADER.header1)}</h1>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                    {ListOfBodyText(BodyText1)}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <ul>
                        <li>
                            <h2>
                               {SmallHeaderText()}
                            </h2>
                        </li>
                        {ListOfItems(LISTTEXT.list1, <HiBriefcase color="#ED1B34" />)}
                    </ul>
                </Col>
            </Row>
            <Row className="image-container">
            <Col>
                <img className="img-in-text" src={image} alt="content"/>
            </Col>
            </Row>
            <Row>
                <h1>{HeaderText(HEADER.header2)}</h1>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                    {ListOfBodyText(BodyText2)}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <ul>
                        <li>
                            <h2>
                               {SmallHeaderText()}
                            </h2>
                        </li>
                        {ListOfItems(LISTTEXT.list2, <HiTruck color="#ED1B34"/>)}
                    </ul>
                </Col>
            </Row>
        </Container>
       </div>
     )
}

export default Jobb